@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
::-webkit-scrollbar {
  height: 4px; /* height of horizontal scrollbar ← You're missing this */
  width: 4px; /* width of vertical scrollbar */
  /* border: 1px solid #d5d5d5; */
}
.Dropdown-control {
  height: 56px;
}
.Dropdown-placeholder {
  margin-top: 5px;
}
.is-selected {
  margin-top: 5px;
}
.Dropdown-arrow {
  top: 23px !important;
}
.LinkS {
  text-decoration: none;
  color: inherit;
}
.rec.rec-arrow-left {
  position: absolute;
  left: 0;
}
.rec.rec-arrow-right {
  right: 0;
  position: absolute;
}

.rec .rec-arrow {
  z-index: 10;
  background-color: rgb(130, 130, 123);
  border-radius: 50%;
}

.rec .rec-arrow:focus:enabled {
  background-color: black;
  color: white;
}

.rec .rec-arrow:focus-visible {
  background-color: black;
  color: white;
}

.appId-head {
  margin-top: auto;
  margin-bottom: auto;
}

#imageSp {
  width: 100%;
  height: 100%;
}

#textarea {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  font-size: 1rem;
  border-radius: 5px;
  border: 0.5px solid lightgray;
  box-sizing: border-box;
}

.appId-subcon4 {
  padding: 2.5% 0px 1%;
}

.appId-link1 {
  text-align: center;
  margin: 0 20px 0 0;
  padding: 3px;
  background: #fff56c;
  border-radius: 5px;
  width: 147px;
}
.appId-link1 p {
  margin: 0 !important;
}

.borderbox {
  border: 0.5px solid lightgray;
  margin: 1% 0px 1%;
  padding: 10px;
  border-radius: 5px;
}

.borderboxTwo {
  border: 0.5px solid lightgray;
  margin: 1% 0 1%;
  padding: 10px;
  border-radius: 5px;
}

.borderbox-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.borderbox-uploaded {
  margin: -20px 0 0 0;
  font-size: 10px;
}

.appId-subcon1,
.appId-subcon2 {
  padding: 1% 0;
}

.appId-liB {
  font-size: 17px;
  width: 200px;
  height: 38px;
  padding: 3px 5px;
  margin: 0 3% 0 0;
  font-weight: 400;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.6);
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
}

#imageset {
  height: 100%;
}

@media (max-width: 480px) {
  .IconPros {
    margin: 0 2em 0 0.5em !important;
  }
  /* focused button */
  .appId-subcon4 {
    flex-wrap: wrap;
    justify-content: center !important;
  }
  .appId-liB {
    margin: 10px 0;
  }
  .rec .rec-arrow:focus:enabled {
    background-color: rgba(0, 0, 0, 0);
    box-shadow: none;
    color: black;
  }
  .rec .rec-arrow {
    background-color: rgba(0, 0, 0, 0);
    box-shadow: none;
  }
  .rec .rec-arrow-left {
    left: -15px;
  }
  .rec .rec-arrow-right {
    right: -5px;
  }
  #imageset {
    height: 10rem;
  }
  .text-center {
    margin: 0 !important;
  }
  #wrapperTwo {
    margin: "9em 2em 2em 2rem";
    scroll-behavior: smooth;
  }
}

button {
  cursor: pointer !important;
}

.rec.rec-arrow:disabled {
  visibility: hidden;
}

/* Pagination Indicator */
.rec .rec-pagination {
  display: none;
}

.rec .rec-slider-container {
  margin: 0;
}

.react-multi-carousel-list {
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  padding-top: 20px;
}

/* mixin for multiline
.block-with-text {
    overflow: hidden;
    position: relative;
    line-height: 1.2em;
    max-height: 6em;
    text-align: justify;
	width: 60%;
	margin-top: 0.6em;
  }
  .block-with-text:before {
    cursor: pointer;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .block-with-text:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: white;
  }

  @media (max-width: 480px) {
    .block-with-text {
	max-height: 153px;
	width: 100%;
    }
} */

body {
  background: #f6f6f6;
  font-size: 16px;
  line-height: 24px;
}
a {
  color: #5d88b3;
}
a .icon {
  line-height: 1.428571429;
}
a:hover .icon {
  text-decoration: none;
}
button {
  outline: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 18px 0;
  font-weight: 400;
}
p {
  margin: 0 0 20px 0;
}
strong {
  font-weight: 700;
}
.page {
  padding: 25px;
}

#wrapperTwo {
  margin: "9em 5em 2em 5rem";
  scroll-behavior: smooth;
}

#wrapper {
  margin: 9em 5em 2em;
}

#wrapperRP {
  margin: 9em 5em 2em;
  height: 80vh;
}

@media (max-width: 480px) {
  #wrapper {
    margin: 8em 2em 1em;
  }
  #wrapperRP {
    margin: 8em 2em 1em;
    height: 100%;
  }
}

